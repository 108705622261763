button {
  cursor: pointer;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.input-field-movement {
  text-align: center;
}

.input-field-number {
  text-align: center;
  width: 50px;
}

.red-input {
  background-color: salmon;
  font-weight: bolder;
}

.remove-button {
  background-color: rgba(0, 0, 0, 0);
  color: red;
  font-weight: bolder;
  border: 0px;
}

#add-exercise-button {
  margin: 3px;
  background-color: rgba(0, 0, 0, 0);
  border-width: 0;
  color: limegreen;
}

.display-button {
  background-color: lightblue;
  height: 50px;
  min-width: 50px;
  margin: 0 1px;
}

.hidden-button {
  background-color: rgba(0, 0, 0, 0);
  border: 0px;
  height: 50px;
  width: 50px;
  margin: 0 1px;
  color: rgba(0, 0, 0, 0);
  pointer-events: none;
}

#restart-button > svg {
  transform: scale(-1, 1); /* flips icon */
  color: rgb(153, 153, 153);
}

.hide {
  display: none !important;
}

/* 
  BEGIN CONFIRMATION OVERLAY STYLING 
*/
.translucent {
  position: absolute;
}

#confirmation-overlay {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  max-width: 100%;
  height: 100%;
  text-align: center;
  background-color: #282c34f5;
  z-index: 2;
  /* font-style: italic; */
}

#confirmation-overlay-button-section {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 15%;
}

.overlay-button {
  display: inline-block;
  padding: 10px;
  border-width: 0;
  border-radius: 5px;
  font-weight: bold;
}

#confirm-restart-button {
  background-color: rgb(160, 0, 0);
}

#confirm-restart-button:hover {
  background-color: red;
}

#decline-restart-button {
  background-color: rgb(134, 134, 134);
}

#decline-restart-button:hover {
  background-color: gainsboro;
}
/* 
  BEGIN CONFIRMATION OVERLAY STYLING 
*/

#form-view > h3 {
  color: orange;
}

#timer-view {
  position: relative;
}

#total-workout-time {
  font-size: 0.75em;
  color: gray;
}

#total-workout-time > span {
  color: rgb(207, 207, 207);
}

#timer-running {
  color: limegreen;
}

#timer-paused {
  color: red;
}

.flicker {
  -webkit-animation: flickerAnimation 1s infinite;
  -moz-animation: flickerAnimation 1s infinite;
  -o-animation: flickerAnimation 1s infinite;
   animation: flickerAnimation 1s infinite;
}


@keyframes flickerAnimation {
  0%   { opacity:1; }
  50%  { opacity:0.1; }
  100% { opacity:1; }
}
@-o-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0.1; }
  100% { opacity:1; }
}
@-moz-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0.1; }
  100% { opacity:1; }
}
@-webkit-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0.1; }
  100% { opacity:1; }
}